/* .back-color {
    background-color: #F2F5F7;
    height: 707px;
    overflow-y: hidden;
} */
.login-card{
    background-color: white;
    width: 570px;
    height: auto;
    margin-left: 420px;
    margin-top: 60px;
    border-radius: 10px;
    border: 1px solid #C3C4C7;
    padding: 50px;
    padding-top: 40px;
    border-color: #C3C4C7;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}
.header {
    margin-top: -3%;
    text-align: center;
    font-family: Open Sans;
}
.input{
    display: flex;
    justify-content: space-between;
}
/* .input-container {
    display: inline-block;
    margin-right: 10%; 
} */
.input-container-1 {
   flex: 1;
}
.input-container-2 {
    flex: 1; 
}
.input-fields{
    color: #3C434A;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    display: grid;
    margin-top: 15px;
}
.inputBox{
    width: 265px;
    height: 40px;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid  #C3C4C7;
    padding-left: 10px;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5px;
    color: #3C434A;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}
.textBox{
    height: 40px;
    width: 550px;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid #C3C4C7;
    padding-left: 10px;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5px;
    color: #3C434A;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}
.submitButton{
    border-radius: 2px;
    border: 1px solid #C3C4C7;
    background: #2874FC;
    width: 100px;
    height: 35px;
    margin-top: 25px;
    margin-left: 200px;
    color: white;
    font-family: Average Sans;
    padding: 5px;
    font-size: 16px;
    font-weight: 100;
    cursor: pointer;
}
.buttons-checkbox{
    display: flex;
}
.checkBox{
    display: flex;
    text-wrap: nowrap;
    margin-top: 25px;
    margin-left:-6px;
   
}
input.remember-me{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.dropdown-container {
    margin-top: 25px;
}

.pass {
    margin-top: 25px;
    position: relative;
}

.label {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    color: #3C434A;
}
.dropdown {
    margin-top: 10px;
    border: 1px solid #C3C4C7;
    padding: 8px 12px;
    width: 564px;
    height: 40px;
    border-radius: 8px;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.dropdown:focus,
.dropdown:hover {
    outline: none;
    border: 1px solid #C3C4C7;
}

.dropdown option {
    margin-top: 15px;
    background-color: #fff; 
    color: #3C434A; 
    font-size: 16px;
    font-family: Open Sans;
}


.signup{
  margin-top: 35px;
  width: 565px;
  background-color: rgb(0, 120, 212);
  color: white;
  font-weight: 600;
  font-family: Open Sans;
  height: 40px;
  border: none;
  line-height: 1.5px;
  font-size: 16px;
  border-radius: 8px;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}
.signup:hover{
    background-color: rgb(16, 110, 190);
    border: 1px solid rgb(16, 110, 190);
}
.button-container{
    margin-left: 195px;
    margin-bottom: -20px;
}

.account {
    font-size: 15px;
    font-weight: 600;
    font-family: Open Sans;
    color: #3C434A;
}

.signin-button {
    background: none;
    border: none;
    padding: 0;
    font-size: 15px;
    color: rgb(0, 120, 212);
    cursor: pointer;
}

.others {
    margin-top: 5px;
}
.eye-icon-signup {
    position: absolute;
    margin-left: 530px;
    top: 41px;
    cursor: pointer;
  }