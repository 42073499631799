.back-color {
    background-color: #F2F5F7;
    height: 707px;
    overflow-y: hidden;
}
.log-login-card{
    background-color: white;
    width: 550px;
    height: auto;
    margin-left: 420px;
    margin-top: 60px;
    border-radius: 10px;
    border: 1px solid #C3C4C7;
    padding: 60px;
    padding-top: 20px;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}
.login-header {
    margin-top: -1%;
    text-align: center;
    font-family: Open Sans;
}
.log-input-fields{
    color: #3C434A;
    font-family: Average Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 100;
    text-align: left;
    display: grid;
    margin-top: 10px;
}
.forgot-password {
    margin-top: 22px;
    text-align: center;
}
.forgot-password-input {
    background: none;
    border: none;
    padding: 0;
    font-size: 15px;
    color: rgb(0, 120, 212);
    cursor: pointer;
    font-family: Open Sans;
    font-weight: 550;
}
.log-textBox{
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid rgba(30, 30, 30, 0.58);
    padding-left: 10px;
    font-family: Average Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5px;
    color: #3C434A;
}
.log-submitButton{
    border-radius: 2px;
    border: 1px solid #C3C4C7;
    background: #2874FC;
    width: 100px;
    height: 35px;
    margin-top: 28px;
    margin-left: 270px;
    color: white;
    font-family: Average Sans;
    padding: 5px;
    font-size: 16px;
    font-weight: 100;
    cursor: pointer;
}
.log-buttons-checkbox{
    display: flex;
}
.log-checkBox{
    display: flex;
    text-wrap: nowrap;
    margin-top: 25px;
    margin-left:-6px;
   
}
input.log-remember-me{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.log-signup{
    background-color: #F2F5F7;
    border: 0px;
    color: #3C434A;
    font-family: Average Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5px;
    margin-top: 25px;
    margin-left: 370px;
    cursor: pointer;
}
.log-signup:hover{
    color: #6c7d8e;
}
.log-button-container{
    
    margin-left: 200px;
}
.login-button { 
  margin-top: 28px;
  width: 565px;
  background-color: rgb(0, 120, 212);
  color: white;
  font-family: Open Sans;
  font-weight: 600;
  height: 40px;
  line-height: 1.5px;
  font-size: 16px;
  border-radius: 8px;
  border : none ;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}
.login-button:hover {
    background-color: rgb(16, 110, 190);
    border: 1px solid rgb(16, 110, 190);
}
.new-account {
    font-size: 15px;
    font-weight: 600;
    font-family: Open Sans;
    color: #3C434A;
}
.signup-button {
    background: none;
    border: none;
    padding: 0;
    font-size: 15px;
    color: rgb(0, 120, 212);
    cursor: pointer;
}

.sign-up-message {
    background-color: #e3e5ed;
    border: 1px solid green;
    margin-top: 30px;
    border-radius: 10px;
    margin-bottom: -9px;
    text-align: center;
    margin-left: 39%;
    width: 20%;
}
.eye-icon {
    position: absolute;
    margin-left: 530px;
    top: 41px;
    cursor: pointer;
  }
.forgot-password-link {
    text-decoration: none;
    color: #2874FC;
    font-family: Open Sans;
}
.login-pass {
    position: relative;
}