.forgotpass-card {
    background-color: white;
    width: 550px;
    height: auto;
    margin-left: 420px;
    margin-top: 60px;
    border-radius: 10px;
    border: 1px solid #C3C4C7;
    padding: 60px;
    padding-top: 20px;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}

.forgot-header {
    margin-top: -1%;
    text-align: center;
    font-family: Open Sans;
}

.forgot-label {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
    color: #3C434A;
}

.forgot-button-container {
    margin-left: -1px;
}

.forgot-token {
    margin-top: 28px;
    width: 565px;
    background-color: rgb(0, 120, 212);
    color: white;
    font-family: Open Sans;
    font-weight: 600;
    height: 40px;
    line-height: 1.5px;
    font-size: 16px;
    border-radius: 8px;
    border : none ;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}

.forgot-token:hover {
    background-color: rgb(16, 110, 190);
    border: 1px solid rgb(16, 110, 190);
}

.return-login {
    margin-top: 20px;
    text-align: center;
}

.instruct {
    font-family: Open Sans;
}

.eye-icon-forgot {
    position: absolute;
    margin-left: 530px;
    top: 362px;
    cursor: pointer;
}