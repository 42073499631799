.facade-footer{
    position: relative;
    bottom: 0;
    width: 100%;
    z-index: 0;
}
.card-display {
    display: flex;
    gap: 50px;
    /* justify-content: center; */
    /* align-items: center; */
    margin-top: 50px;
    margin-bottom: 250px;
    flex-direction: row;
    flex-wrap: wrap;

}
.hide-scrollbar {
    overflow: hidden;
  }
.tab-display{
    display: flex;
    width: 100%;
    overflow-y: auto;
    background-color: #f7f7f7;
    border-bottom: 10px solid white;
}
.tabs{
    border: 1px solid transparent;
    background-color: #f7f7f7;
    align-items: stretch;
    border-right: 1px solid rgb(187, 187, 187);
    width: 160px;
    padding: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    max-width: 100%; 
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
}
.current-tab {
    background-color: white;
    border: 1px solid #3BA3DD;    
    border-radius: 5px;
    border-bottom: none;
}
.tab-content {
    display: flex;
    align-items: center; 
  }
  
.tab-icon {
    margin-right: 8px; 
    height: 20px;
}
.close-button {
    border: none;
    margin-left: 10px;
    background-color: white;
    margin-left: auto;
    color: #d9d9d9;
}
.frame-size {
    margin-left: 45%;
    margin-top: -32%;
}